import React from 'react';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Dialog,
    BottomNavigation,
    Grid,
    Box,
    Button,
    ListItemSecondaryAction, Drawer, TextField, FormControl, DialogActions
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AppContext from '../AppContext'
import CloseIcon from "@material-ui/icons/Close";
import Util from "./service/Util";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Site from './service/Site';
import Cart from './service/Cart';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

class OfferOptionsDialog extends React.Component {
    static contextType= AppContext;
    constructor(props) {
        super(props);
        AppContext.app = this;
        this.state = {
            show:false,
            offer:{},
            showDrawer: false,
            chosen: {},
            detail:{},
            offerTotal:0,
            count:1,
            sections:[]
        }
    }
    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name==="OfferOptionsDialog" && e.show){
                if (e.extra.offerFood){
                    this.state.detail.selectedFood=e.extra.offerFood;
                    this.state.detail.selectedFood.count=1;
                    let extra = 0;
                    this.state.offer.details.map((detail)=>{
                        if (detail.selectedFood && detail.selectedFood.extraOfferPrice) {
                            extra+=detail.selectedFood.extraOfferPrice;
                        }
                    })
                    this.state.offer.total =this.state.offer.price *1 + extra;
                    this.setState({detail:this.state.detail, offerTotal: this.state.offer.total, count:1});
                }
                else {
                    let offer1 = JSON.parse(JSON.stringify(e.extra.offer))
                    let sections1 = Array.from(new Set(offer1.details.map(o=>o.offersectionno*1))).sort();
                    let sections = {}
                    for(let i=0; i<sections1.length; i++)
                        sections[sections1[i]] = offer1.details.filter(o=>o.offersectionno*1==sections1[i])
                    for(let i=0; i<sections1.length; i++){
                        let details = sections[sections1[i]]

                        let foodOptions1 = this.findOptions(details[0])
                        if (details && foodOptions1 && foodOptions1.length==1)
                            if ((!foodOptions1[0].prices || foodOptions1[0].prices.length==1) && !Util.instance.foodHasTopping(foodOptions1[0]))
                                details[0].selectedFood=foodOptions1[0]
                    }
                    this.setState({
                        offer: offer1,
                        show: e.show,
                        showDrawer: false,
                        chosen: {},
                        offerTotal: e.extra.offer.price * 1,
                        count: 1,
                        sections: sections
                    })
                }
            }
        })
    }
    findOptions(detail){
        if (!detail) return [];
        let foods = [];
        let foodinfo = Site.instance.foodinfo;
        if (detail.typeid>0) {
            let o = Util.instance.findByKeyValue(foodinfo.types, 'typeid',detail.typeid);
            if (o && o.title){
                foods=foodinfo.foods.filter((f)=>f.typeid===o.typeid);
            }
        }
        if (detail.foodid>0) {
            foods=foodinfo.foods.filter((f)=>f.foodid===detail.foodid);
        }
        return foods;
    }
    chooseFood(food){
        this.setState({
            showDrawer:false
        })
        this.state.detail.selectedFood=food;
        if (this.state.detail && this.state.detail.offerdetailid)
            this.state.detail.selectedFood.offerdetailid=this.state.detail.offerdetailid
        if ((food.prices && food.prices.length>1)
            ||
            (food.toppings && food.toppings.length>1)
        ){
            Cart.instance.showDialogSubject.next({
                name: "FoodOptionsDialog",
                show: true,
                extra: {offerDetail:this.state.detail, fromOffers:1}
            })
        }
    }
    sectionClicked(offerDetail){

        let options = this.findOptions(offerDetail)
        try {
            let sectionNo = offerDetail.offersectionno * 1
            let details = this.state.sections[sectionNo]
            for (let i = 0; i < details.length; i++) {
                delete details[i].selectedFood
            }
        }catch (e) {
        }

        if (options.length==1) {
            if ((options[0].prices && options[0].prices.length==1)
                && (!options[0].toppings || options[0].toppings.length==0)){
                offerDetail.selectedFood = options[0]
                offerDetail.selectedFood.offerdetailid=offerDetail.offerdetailid
                this.setState({})
                return
            } else {
                this.setState({detail: offerDetail}, () => {
                    this.chooseFood(options[0])
                });
            }
            return
        }
        /*
        if (options.length==1 && options[0].prices && options[0].prices.length==1) {
            offerDetail.selectedFood = options[0]
            let sectionNo = offerDetail.offersectionno*1
            let details = this.state.sections[sectionNo]
            for(let i=0; i<details.length; i++){
                if (details[i]!==offerDetail)
                    delete details[i].selectedFood
            }

            this.setState({})
            return
        }*/
        this.setState({showDrawer: true, detail: offerDetail});
    }
    render() {
        let offer=this.state.offer;
        let sections=this.state.sections;
        return (
            this.state.show &&
            <React.Fragment>
                    <Dialog fullWidth open={true} onClose={() => this.setState({show:false})}
                            style={{maxWidth: '960px', margin: '0 auto'}}
                            className="dlg1 minWidth300"
                    >
                        <AppBar position="sticky">
                            <Toolbar>
                                <IconButton edge="start" color="inherit"
                                            onClick={() => this.setState({show:false})}
                                            aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" style={{flexGrow: 1}}>
                                    {offer && offer.title}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <List style={{marginBottom:10}}>
                            {
/*
                                sections && sections.map((section) => {
                                    let odetails = this.state.offer.details.filter(o=>o.offersectionno*1==section*1)
                                    let offerDetail = odetails.length==1? odetails[0] : null

                                    return (<React.Fragment>
                                        { odetails.length>1 ?
                                        <ToggleButtonGroup key={section}
                                            color="primary"
                                            exclusive
                                            style={{marginBottom: 5, marginTop: 5}}>
                                            {odetails.map((d)=>(
                                                <ToggleButton key="{d.title}" value="{d.title}">{d.title}</ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    :
                                        <ListItem button selected key={section}
                                                onClick={() => {
                                                    this.setState({
                                                        showDrawer: true,
                                                        detail: offerDetail
                                                    });
                                                }}
                                                style={{marginBottom: 5, marginTop: 5}}>
                                        <ListItemText primary={offerDetail.title} />
                                        <ListItemSecondaryAction onClick={() => {
                                            this.setState({showDrawer: true, detail: offerDetail});
                                        }}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <span>{offerDetail.selectedFood ? offerDetail.selectedFood.title : "Choose"}</span>
                                                <ArrowForwardIosIcon/>
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>}
                                    </React.Fragment>)
                                })
*/

                                offer && offer.details.map((offerDetail) => (
                                    <ListItem button selected
                                    key={offerDetail.offerdetailid}
                                              onClick={() => { this.sectionClicked(offerDetail) }}
                                              style={{ marginBottom: 5, marginTop: 5}}>
                                        <ListItemText
                                            primary={
                                                !offerDetail.selectedFood ? <span>
                                                {this.state.sections[offerDetail.offersectionno].length>1 && this.state.sections[offerDetail.offersectionno][this.state.sections[offerDetail.offersectionno].length-1]!==offerDetail &&
                                                    <div style={{marginTop: 32,position: "absolute",fontSize: 10,fontWeight: "bold"}}> OR</div>
                                                }
                                                {offerDetail.title}
                                                </span> : <span>&nbsp;</span>}
                                        />

                                        <ListItemSecondaryAction onClick={() => { this.sectionClicked(offerDetail) }}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <span>{offerDetail.selectedFood? offerDetail.selectedFood.title : "Choose"}</span>
                                                <ArrowForwardIosIcon/>
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))

                            }
                        </List>
                        <TextField
                            label="Note"
                            variant="outlined"
                            inputRef={e => this.note = e}
                            style={{marginLeft:20, marginRight:20}}
                            size="small"
                        />
                        <TextField

                            label="Quantity"
                            type="number"
                            placeholder="Quantity"
                            margin="normal"
                            defaultValue="1"
                            onChange={(e)=>{
                                e.preventDefault();
                                let cnt = Math.max(1, e.target.value);
                                this.state.offer.count=cnt;
                                this.setState({count: cnt})
                            }
                            }
                            inputRef={e => this.count = e}
                            style={{marginLeft:20, marginRight:20, maxWidth:100}}
                        />

                        <DialogActions style={{ position:"sticky", bottom:0,zIndex: 200,
                            marginTop: 0,
                            borderTop: "1px dotted red",
                            textAlign:"center",
                            backgroundColor:"white",
                            height:40}}>

                                <Box component="span" m={1} style={{ margin: "0 auto"}}>
                                    {Util.instance.formatPrice(this.state.offerTotal*this.state.count)}
                                </Box>
                                <Button style={{position: "absolute", right: 0}} color={"secondary"}
                                        onClick={() => {
                                            let selectedCount=0;
                                            this.state.offer.details.forEach((d)=>{
                                                if (d.selectedFood) selectedCount++;
                                            })
                                            if (selectedCount<Object.keys(this.state.sections).length){
                                                Site.instance.showToast("Choose all items", "error", "bottom")
                                            }else {
                                                this.state.offer.note=this.note.value;
                                                Cart.instance.addOfferToCart(this.state.offer);
                                                this.setState({show: false})
                                            }
                                        }}>ADD <ArrowForwardIosIcon/></Button>
                        </DialogActions>
                    </Dialog>
        <Drawer anchor="bottom"
                open={this.state.showDrawer}
                onClose={() => this.setState({showDrawer: false})}
                PaperProps={{ style: { maxWidth: 500, margin: "0 auto"}}}
        >
            <List >
                {
                    this.state.detail && this.findOptions(this.state.detail).map((food) => (
                        <ListItem button
                                  key={food.foodid}
                                  onClick={() => this.chooseFood(food)}>
                            <ListItemText primary={
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{flexGrow: 1}}>{food.title}</div>
                                    {this.state.detail.selectedFood && this.state.detail.selectedFfoodid === food.foodid &&
                                    <CheckOutlinedIcon color="disabled"/>
                                    }
                                </div>}/>
                        </ListItem>
                    ))
                }
            </List>
        </Drawer>
            </React.Fragment>

    );
    }
}

export default OfferOptionsDialog;
